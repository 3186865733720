/* eslint-disable complexity */
import {
  Container,
  Backdrop,
  CircularProgress,
  CssBaseline
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import StyledEngineProvider from '@mui/styled-engine/StyledEngineProvider';
import { Box } from '@mui/system';
import { useAppContext } from 'context/uuids';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUnimpersonateMutation } from 'services/adminUsers';
import { setCredentials } from 'services/slice/authSlice';
import { ImpersonatingSnackbar } from 'components/ImpersonatingSnackbar/ImpersonatingSnackbar';
import { parseJwt } from 'utils/commonUtils';
import { getModel } from 'utils/modelUtils';
import { Header } from '../Header/Header';
import { Sidebar } from '../Sidebar/Sidebar';
import { theme } from './theme';
import { LayoutProps } from './types';

const isImpersonating = (jwtToken): boolean => {
  if (!jwtToken) return false;
  const parsedToken = jwtToken && parseJwt(jwtToken);
  return Boolean(parsedToken && parsedToken.impersonatedBy);
};

export function Layout({
  children,
  fullWidth = false,
  isLoanForm = true,
  isTable = false,
  hideHeader = false,
  removeMargin = false,
  sxWrapper = {}
}: LayoutProps): React.ReactElement {
  const router = useRouter();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [unimpersonate] = useUnimpersonateMutation();
  const { profile } = useAppContext();
  const jwtToken = getModel('accessToken', null);
  const impersonating = isImpersonating(jwtToken);

  const endImpersonateSession = () => {
    if (!impersonating) return;
    return unimpersonate()
      .unwrap()
      .then((newToken) => {
        dispatch(
          setCredentials({
            accessToken: newToken.accessToken,
            refreshToken: newToken.refreshToken
          })
        );
        window.location.pathname = '/pipeline';
      });
  };
  const handleStart = useCallback(() => {
    setLoading(true);
  }, [setLoading]);

  const handleStop = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const isLoan = useCallback(
    ({ isTrue, isFalse, isTablePage }) =>
      isLoanForm ? isTrue : isTable ? isTablePage : isFalse,
    [isLoanForm, isTable]
  );

  useEffect(() => {
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router, handleStart, handleStop]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="xl" sx={{ px: '0 !important' }}>
          {!hideHeader && <Header />}
          <Box
            sx={
              !hideHeader && {
                mt: {
                  xs: isLoan({
                    isTrue: '127px',
                    isFalse: '52px',
                    isTable: '52px'
                  }),
                  sm: '60px'
                },
                display: 'flex',
                flex: 1
              }
            }
          >
            {isLoanForm && <Sidebar />}
            <Box
              component="main"
              sx={{
                flex: '1',
                transition: 'max-width 0.3s',
                maxWidth: {
                  xs: '100%',
                  sm: isLoan({
                    isTrue: 'calc(100% - 304px)',
                    isFalse: 'calc(100%)',
                    isTable: 'calc(100%)'
                  })
                },
                backgroundColor: isLoanForm ? 'white' : 'background.default',
                ...sxWrapper
              }}
            >
              <Box
                sx={
                  !removeMargin
                    ? {
                        margin: isLoan({
                          isTrue: {
                            xs: '24px 16px 48px 16px',
                            md: fullWidth
                              ? '64px 72px 78px 72px'
                              : '64px 120px 78px 96px'
                          },
                          isTablePage: {
                            md: '24px 16px 48px 16px',
                            xs: '55px 16px 48px 16px'
                          },
                          isFalse: {
                            xs: '12px 16px 48px 16px',
                            md: fullWidth
                              ? '32px 72px 78px 72px'
                              : '32px 120px 78px 96px'
                          }
                        }),
                        maxWidth: fullWidth ? '100%' : '921px'
                      }
                    : undefined
                }
              >
                {children}
              </Box>
            </Box>
          </Box>
          {impersonating && (
            <ImpersonatingSnackbar
              name={`${profile?.firstName} ${profile?.lastName}`}
              endSession={endImpersonateSession}
            />
          )}
        </Container>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
