import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1278BC',
      light: '#E1F2FA',
      dark: '#003077'
    },
    secondary: {
      main: '#878C99',
      light: '#FFF7E0'
    },
    text: {
      primary: '#06112E',
      secondary: '#878C99',
      disabled: '#AEB2BD'
    },
    action: {
      selected: '#4FA5DF14',
      active: '#0B18388A'
    },
    background: {
      default: '#FAFAFA'
    },
    error: {
      dark: '#621b16',
      main: '#F44336'
    },
    success: {
      main: '#4CAF50',
      contrastText: '#ffffff'
    },
    warning: {
      main: '#DEB407',
      contrastText: '#ffffff'
    },
    danger: {
      main: '#ED6C02',
      contrastText: '#ffffff'
    },
    black: {
      main: '#ffffff',
      contrastText: '#000000'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 877,
      md: 1024,
      lg: 1200,
      xl: 1440
    }
  },
  typography: {
    subtitle1: {
      fontWeight: 500,
      lineHeight: '28px'
    },
    subtitle2: {
      fontWeight: 500,
      letterSpacing: '0.00938rem'
    },
    body2: {
      fontWeight: 400,
      letterSpacing: '0.013rem'
    },
    button: {
      textTransform: 'none'
    },
    h5: {
      color: '#06112E'
    },
    h6: {
      color: '#06112E'
    }
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: {
            variant: 'outlined',
            color: 'black'
          },
          style: {
            border: `1px solid #000000`,
            color: '#000000',
            '&:hover': {
              border: `1px solid #000000`,
              color: '#000000'
            },
            '&:active': {
              border: `1px solid #000000`,
              backgroundColor: '#000000',
              color: '#fff'
            }
          }
        }
      ]
    }
  },
  spacing: 4
});
