import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  AppBar,
  Button,
  Container,
  IconButton,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useAppContext } from 'context/uuids';
import Link from 'next/link';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageMenu } from './LanguageMenu';
import { RightSideHeader } from './RightSideHeader';
import { headerStyles } from './headerStyles';

const adminPages = [];

const borrowerPages = [];

export function Header(): React.ReactElement {
  const { t, i18n } = useTranslation();

  const { profile } = useAppContext();

  const [anchorElLanguage, setAnchorElLanguage] = useState(null);

  const handleOpenLanguageMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElLanguage(event.currentTarget);
    },
    [setAnchorElLanguage]
  );

  const handleCloseLanguageMenu = useCallback(() => {
    setAnchorElLanguage(null);
  }, [setAnchorElLanguage]);

  const pages =
    profile?.role === 'borrower' || profile?.role === 'co-borrower'
      ? borrowerPages
      : adminPages;

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const toggle = () => {
    setIsSideBarOpen(!isSideBarOpen);
  };

  const isBorrower = profile?.role === 'borrower' ? true : false;

  return (
    <AppBar
      position="fixed"
      sx={headerStyles.appbar}
      color="secondary"
      enableColorOnDark
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={headerStyles.toolbar}>
          <Link href={isBorrower ? '/my-loans' : '/pipeline'}>
            <Box
              component="img"
              src="/images/logo_top_header.png"
              sx={headerStyles.image}
            />
          </Link>

          <Box sx={headerStyles.container}>
            {isBorrower && (
              <>
                <Tooltip
                  title={t('header.choose_language')}
                  sx={headerStyles.languageTooltip}
                >
                  <IconButton
                    sx={headerStyles.sideBarDropdownContainer}
                    size="medium"
                    onClick={handleOpenLanguageMenu}
                  >
                    <Box sx={headerStyles.iconContainer}>
                      <Typography
                        sx={headerStyles.sideBarDropdownText}
                        variant="body1"
                        mr={2}
                      >
                        {i18n?.language?.toUpperCase()}
                      </Typography>
                      <KeyboardArrowDownIcon
                        htmlColor="#DEB407"
                        fontSize="small"
                      />
                    </Box>
                  </IconButton>
                </Tooltip>
                <LanguageMenu
                  anchorElLanguage={anchorElLanguage}
                  handleCloseLanguageMenu={handleCloseLanguageMenu}
                />
              </>
            )}
          </Box>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={headerStyles.logo}
            className="no-print"
          >
            AMG
          </Typography>
          <Box sx={headerStyles.wrap}>
            {pages.map((page) => (
              <Button key={page.id} onClick={toggle} sx={headerStyles.button}>
                <Link href={page.link}>
                  <Typography
                    variant="subtitle1"
                    textAlign="center"
                    fontWeight="700"
                  >
                    {t(page.name)}
                  </Typography>
                </Link>
              </Button>
            ))}
          </Box>
          <RightSideHeader
            anchorElLanguage={anchorElLanguage}
            handleOpenLanguageMenu={handleOpenLanguageMenu}
            handleCloseLanguageMenu={handleCloseLanguageMenu}
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
