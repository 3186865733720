import { Check, Edit } from '@mui/icons-material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'next-i18next';

export function StepperLabel({ onClick, subMenu, currentStep, doneSteps }) {
  const { t } = useTranslation();

  const isCurrent = currentStep?.subMenuId === subMenu.id;
  const isDone = doneSteps[subMenu.id] && !isCurrent;
  const isNotDone = !isCurrent && !isDone;

  const text = t(subMenu.label);

  return (
    <ListItem onClick={onClick} sx={{ p: 0 }}>
      <ListItemButton sx={{ pl: 10.5, py: 1.5 }}>
        {isCurrent && (
          <>
            <ListItemIcon sx={{ minWidth: '16px', mr: 2 }}>
              <Edit
                sx={{
                  color: 'primary.dark',
                  width: 16,
                  height: 16
                }}
              />
            </ListItemIcon>
            <ListItemText
              sx={{
                '& .MuiTypography-body1': {
                  color: 'primary.dark',
                  fontWeight: '500'
                }
              }}
              primary={text}
            />
          </>
        )}
        {isDone && (
          <>
            <ListItemIcon sx={{ minWidth: '16px', mr: 2 }}>
              <Check
                sx={{
                  color: 'success.main',
                  width: 16,
                  height: 16
                }}
              />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: 'primary.dark'
              }}
              primary={text}
            />
          </>
        )}
        {isNotDone && (
          <>
            <ListItemText
              sx={{
                color: 'text.disabled'
              }}
              primary={text}
            />
          </>
        )}
      </ListItemButton>
    </ListItem>
  );
}
