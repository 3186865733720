import React from 'react';
import { Stepper } from './Stepper';

export function Sidebar(): React.ReactElement {
  return (
    <>
      <Stepper />
    </>
  );
}
