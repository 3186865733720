import { IEditUserFormModel } from 'models/EditUserFormModel';
import { baseApi } from './baseApi';
import { IUser } from './models/IUser';
import { parsePictureAdmin } from './parsers/admin';
import { parseEditUserFormToBody } from './parsers/editUser';

export const adminUsers = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<IUser[], void>({
      query: () => ({
        url: '/admin/users'
      }),
      providesTags: ['ADMIN_USERS']
    }),
    getUser: builder.query<IUser, { uuid: string }>({
      query: (request) => ({
        url: `/admin/users/${request.uuid}`
      }),
      providesTags: ['ADMIN_USER']
    }),
    updateUser: builder.mutation<
      IUser,
      { uuid: string; profileValues: IEditUserFormModel }
    >({
      query: (request) => ({
        url: `/admin/users/${request.uuid}`,
        method: 'PUT',
        body: parseEditUserFormToBody(request.profileValues)
      }),
      invalidatesTags: ['ADMIN_USERS', 'ADMIN_USER']
    }),
    activateUser: builder.mutation<IUser, { isActive: boolean; uuid: string }>({
      query: (request) => ({
        url: `/admin/users/${request.uuid}`,
        method: 'PUT',
        body: { isActive: request.isActive }
      }),
      invalidatesTags: ['ADMIN_USERS', 'ADMIN_USER']
    }),
    impersonate: builder.mutation<
      { accessToken: string; refreshToken: string },
      { uuid: string }
    >({
      query: (request) => ({
        url: `/sessions/impersonate/${request.uuid}`,
        method: 'GET'
      }),
      invalidatesTags: (result) =>
        result && ['PIPELINE_DETAILS', 'PIPELINE', 'OFFICERS', 'PROFILE']
    }),
    unimpersonate: builder.mutation<
      { accessToken: string; refreshToken: string },
      void
    >({
      query: () => ({
        url: `/sessions/unimpersonate/`,
        method: 'GET'
      }),
      invalidatesTags: (result) =>
        result && ['PIPELINE_DETAILS', 'PIPELINE', 'OFFICERS', 'PROFILE']
    }),
    updateProfilePicture: builder.mutation<
      IUser,
      { uuid: string; imageFile: File }
    >({
      query: (request) => ({
        url: `/admin/users/${request.uuid}/profile-picture`,
        method: 'PUT',
        body: parsePictureAdmin(request.imageFile)
      }),
      invalidatesTags: ['PROFILE']
    })
  })
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useUpdateProfilePictureMutation,
  useActivateUserMutation,
  useImpersonateMutation,
  useUnimpersonateMutation
} = adminUsers;
