export const snackbarStyles = {
  button: { color: 'white' },
  snackbar: {
    backgroundColor: 'var(--primary-color)',
    position: 'sticky',
    bottom: '2px',
    mx: 1,
    zIndex: 20
  }
};
