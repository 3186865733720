/* eslint-disable complexity */
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  IconButton,
  StepConnector,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import { default as MuiStepper } from '@mui/material/Stepper';
import { useAppContext } from 'context/uuids';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePricingQuery } from 'services/loan';
import {
  canGoToRoute,
  getCurrentStep,
  parseDoneSteps,
  useLoanPending
} from 'services/utils';
import { HelpCard } from 'components/HelpCard/HelpCard';
import { menu, menuWithPricing } from 'constants/menu';
import { stepRoutes } from 'constants/step-routes';
import { stepperStyles } from 'styles/stepperStyles';
import { getOfficerFromLoanPending } from 'utils/commonUtils';
import { StepperLabel } from './StepperLabel';

const pricingEnabled = process.env.NEXT_PUBLIC_PRICING_ENABLED === 'true';

export const Stepper: React.FC = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [doneSteps, setDoneStep] = useState({});
  const [currentStep, setCurrentStep] = useState<{
    menuId: string;
    subMenuId: string;
    menuSelectedIndex: number;
  } | null>(null);
  const router = useRouter();
  const { loanUuid } = useAppContext();
  const loanStatus = useLoanPending();
  const officer = getOfficerFromLoanPending(loanStatus.data);
  const { data } = usePricingQuery(
    {
      loanID: loanUuid
    },
    {
      skip: !(
        loanUuid &&
        loanUuid !== 'LOAN_DONE' &&
        officer?.user?.officerInfo?.pricingEngineUsername &&
        loanStatus.data.loanApplicationFormProgress.find(
          (progress) => progress.section === 'about-the-loan'
        )?.done
      )
    }
  );

  const hasPricing =
    pricingEnabled &&
    officer?.user?.officerInfo?.pricingEngineUsername &&
    Array.isArray(data) &&
    data.length > 0;

  const onClickMenu = useCallback(
    (menuItem, menuIndex) => () => {
      if (menuItem.route) {
        const canGoMenu = canGoToRoute({
          applicationProgress: loanStatus?.data,
          route: menuItem.route,
          hasPricing
        });
        if (canGoMenu) {
          router.push(menuItem.route);
        }
      } else {
        if (menuIndex === activeStep) {
          setActiveStep(null);
        } else {
          setActiveStep(menuIndex);
        }
      }
    },
    [activeStep, loanStatus?.data, router]
  );

  const onClickSubMenu = useCallback(
    (subMenu) => () => {
      const canGoMenu = canGoToRoute({
        applicationProgress: loanStatus?.data,
        route: subMenu.route,
        hasPricing
      });
      if (subMenu.route && canGoMenu) {
        router.push(subMenu.route);
      }
    },
    [loanStatus?.data, router]
  );

  const customApplicationModel = useMemo(() => {
    if (loanStatus.data) {
      const progress = [...loanStatus.data.loanApplicationFormProgress];
      progress.splice(2, 0, {
        module: 'start',
        section: 'your-pricing',
        done: hasPricing && loanStatus.data.pricingOption !== null
      });
      return {
        ...loanStatus,
        data: {
          ...loanStatus.data,
          loanApplicationFormProgress: progress
        }
      };
    }
    return loanStatus;
  }, [hasPricing, loanStatus]);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));

  const getStep = useCallback(
    (route: string) => {
      let menuId = '';
      let subMenuId = '';
      let menuSelectedIndex = 0;
      const lmenu = hasPricing ? menuWithPricing : menu;
      lmenu.forEach((menuItem, index) => {
        menuItem.subMenu?.forEach((subMenuItem) => {
          if (subMenuItem.route === route) {
            subMenuId = subMenuItem.id;
            menuId = menuItem.id;
            menuSelectedIndex = index;
            setActiveStep(index);
          }
        });
        if (menuItem.route === route) {
          menuId = menuItem.id;
          menuSelectedIndex = index;
          setActiveStep(index);
        }
      });
      return {
        menuId,
        subMenuId,
        menuSelectedIndex
      };
    },
    [hasPricing]
  );

  useEffect(() => {
    if (customApplicationModel.isSuccess) {
      setDoneStep(parseDoneSteps(customApplicationModel?.data, hasPricing));
    }
  }, [customApplicationModel]);

  useEffect(() => {
    if (router) {
      setCurrentStep(getStep(router.pathname));
    }
  }, [getStep, router]);

  const goToLastStepOfLoan = useCallback(() => {
    const loanFlag = loanUuid === 'LOAN_DONE';
    const isInSubmittedStep =
      router?.query?.ln && router.pathname === stepRoutes.APPROVAL.route;
    if (customApplicationModel.isSuccess || loanFlag) {
      const canGo = canGoToRoute({
        applicationProgress: customApplicationModel.data,
        route: router.pathname,
        hasPricing
      });
      if (canGo === false && !isInSubmittedStep) {
        const step = getCurrentStep(customApplicationModel.data, hasPricing);
        router.push(step.route);
      }
      if (loanFlag && !isInSubmittedStep) {
        router.push(stepRoutes.MY_LOANS.route);
      }
    }
  }, [
    customApplicationModel.data,
    customApplicationModel.isSuccess,
    loanUuid,
    router
  ]);

  useEffect(() => {
    goToLastStepOfLoan();
  }, [goToLastStepOfLoan]);

  const showOfficerMenu =
    customApplicationModel?.data?.loanApplicationFormProgress?.find(
      ({ section }) => section === 'select-officer'
    ).done === false;

  const shouldHideStep = useCallback(
    ({ id }) => {
      if (id === stepRoutes.SELECT_OFFICER.id) {
        if (showOfficerMenu) return true;
        return false;
      } else if (id === stepRoutes.YOUR_PRICING.id) {
        if (!hasPricing) return false;
        return true;
      } else {
        return true;
      }
    },
    [hasPricing, showOfficerMenu]
  );

  const menuList = hasPricing ? menuWithPricing : menu;
  return (
    <Box sx={stepperStyles.wrap}>
      <Box
        sx={{
          height: {
            xs: 68,
            sm: 'calc( 100% - 92px)'
          },
          overflow: {
            xs: 'visible',
            sm: 'auto'
          },
          position: {
            xs: 'fixed'
          },
          left: {
            xs: '0',
            sm: 'auto'
          },
          top: {
            xs: '52px',
            sm: 'auto'
          },
          width: {
            xs: '100%',
            sm: 283
          },
          mr: 0,
          ml: {
            xs: 0,
            sm: 5
          },
          mt: {
            sm: 6
          },
          zIndex: 20
        }}
      >
        <Box
          sx={{
            minHeight: '100%',
            position: 'relative',
            pb: {
              xs: '0',
              sm: '65px'
            },
            pt: {
              xs: '0'
            }
          }}
        >
          <MuiStepper
            activeStep={currentStep?.menuSelectedIndex}
            orientation={isTablet ? 'horizontal' : 'vertical'}
            color="primary.dark"
            connector={isTablet ? <StepConnector /> : null}
            sx={stepperStyles.container}
          >
            {menuList.map((menuItem, menuIndex) => {
              const current = menuIndex === currentStep?.menuSelectedIndex;
              return (
                <Step
                  key={menuItem.id}
                  completed={doneSteps[menuItem.id]}
                  active={currentStep?.menuId === menuItem.id}
                  expanded={menuIndex === activeStep}
                  sx={stepperStyles.step(current)}
                >
                  <StepLabel
                    sx={stepperStyles.label(current)}
                    onClick={onClickMenu(menuItem, menuIndex)}
                  >
                    {t(menuItem.label)}
                    {!isTablet && !!menuItem.subMenu && (
                      <IconButton sx={stepperStyles.icon}>
                        {menuIndex === activeStep ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </IconButton>
                    )}
                  </StepLabel>
                  {menuIndex === activeStep && !isTablet && (
                    <StepContent sx={stepperStyles.stepContent}>
                      <Box>
                        <List
                          sx={stepperStyles.list}
                          component="nav"
                          aria-labelledby="navigation"
                        >
                          {menuItem.subMenu?.map(
                            (subMenu) =>
                              shouldHideStep(subMenu) && (
                                <StepperLabel
                                  onClick={onClickSubMenu(subMenu)}
                                  key={subMenu.id}
                                  subMenu={subMenu}
                                  currentStep={currentStep}
                                  doneSteps={doneSteps}
                                />
                              )
                          )}
                        </List>
                      </Box>
                    </StepContent>
                  )}
                </Step>
              );
            })}
          </MuiStepper>
          <HelpCard />
        </Box>
      </Box>
    </Box>
  );
};
