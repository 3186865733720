export const stepperStyles = {
  container: {
    gap: {
      xs: '0',
      sm: '24px'
    },
    zIndex: 3,
    '& .MuiStepIcon-root.Mui-active': {
      color: 'primary.dark'
    },
    '& .MuiStepLabel-label.Mui-completed': {
      color: 'success.main'
    },
    '& .MuiStepLabel-label.Mui-active': {
      color: 'primary.dark'
    },
    '& .MuiStepIcon-root.Mui-completed': {
      color: 'success.main',
      zIndex: 1
    },
    backgroundColor: 'background.default',
    py: { xs: 3, md: 0 }
  },
  wrap: {
    width: {
      xs: 'auto',
      sm: '303px'
    },
    top: '52px',
    backgroundColor: 'background.default'
  },
  step: (active: boolean): any => ({
    '& .MuiStepLabel-root': {
      position: 'relative'
    },
    '& .MuiStepLabel-iconContainer': {
      pr: { xs: active ? 2 : 0, sm: 4 }
    },
    pl: { xs: 3, sm: 2 },
    pr: { xs: active ? 1.5 : 3, sm: 2 }
  }),
  icon: {
    position: 'absolute',
    left: '190px',
    top: 4
  },
  stepContent: {
    border: 0,
    m: 0,
    p: 0
  },
  label: (active: boolean): any => ({
    height: '48px',
    '.MuiStepLabel-label': {
      display: {
        xs: active ? 'block' : 'none',
        sm: 'block'
      }
    }
  }),
  list: { p: 0, width: '100%', maxWidth: 360 }
} as const;
