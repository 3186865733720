import { Button, SnackbarContent } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { snackbarStyles } from './snackbarStyles';

export const ImpersonatingSnackbar = ({
  name,
  endSession
}: {
  name: string;
  endSession: () => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const handleEndSession = useCallback(() => {
    endSession();
  }, [endSession]);

  const action = (
    <Button sx={snackbarStyles.button} onClick={handleEndSession}>
      {t('impersonate.end_session').toUpperCase()}
    </Button>
  );

  return (
    <SnackbarContent
      message={t('impersonate.viewing_as', { name })}
      action={action}
      sx={snackbarStyles.snackbar}
    />
  );
};
