import { PersonOutline } from '@mui/icons-material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, IconButton, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useAppContext } from 'context/uuids';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IRoles } from 'services/models/IRoles';
import { HeaderMenu } from './HeaderMenu';
import { LanguageMenu } from './LanguageMenu';
import { headerStyles } from './headerStyles';

export function RightSideHeader({
  anchorElLanguage,
  handleOpenLanguageMenu,
  handleCloseLanguageMenu
}: {
  handleOpenLanguageMenu: (event: React.MouseEvent<HTMLElement>) => void;
  handleCloseLanguageMenu: () => void;
  anchorElLanguage: HTMLElement;
}): React.ReactElement {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const { profile } = useAppContext();
  const { t, i18n } = useTranslation();
  const handleOpenUserMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    },
    [setAnchorElUser]
  );

  const isBorrower = profile?.role === IRoles.BORROWER;

  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null);
  }, [setAnchorElUser]);

  return (
    <Box sx={headerStyles.rightSideHeader}>
      {isBorrower && (
        <>
          <Tooltip title={t('header.choose_language')}>
            <IconButton
              sx={headerStyles.languageDropdownButtonContainer}
              size="medium"
              onClick={handleOpenLanguageMenu}
            >
              <Box sx={headerStyles.iconContainer}>
                <Typography
                  sx={headerStyles.dropdownText}
                  variant="body1"
                  mr={2}
                >
                  {i18n.language?.toUpperCase?.()}
                </Typography>
                <KeyboardArrowDownIcon htmlColor="#DEB407" fontSize="small" />
              </Box>
            </IconButton>
          </Tooltip>
          <LanguageMenu
            anchorElLanguage={anchorElLanguage}
            handleCloseLanguageMenu={handleCloseLanguageMenu}
          />
        </>
      )}
      <Tooltip title={t('header.open_settings')}>
        <IconButton
          sx={headerStyles.dropdownButtonContainer}
          size="medium"
          onClick={handleOpenUserMenu}
        >
          <Box sx={headerStyles.iconContainer}>
            <Avatar sx={headerStyles.icon} alt={t('user_image')}>
              <PersonOutline />
            </Avatar>
            <Typography sx={headerStyles.dropdownText} variant="body1" mr={2}>
              {t('hello_user', { name: profile?.firstName })}
            </Typography>
            <ArrowDropDown htmlColor="white" />
          </Box>
        </IconButton>
      </Tooltip>
      <HeaderMenu
        anchorElUser={anchorElUser}
        handleCloseUserMenu={handleCloseUserMenu}
      />
    </Box>
  );
}
