import { Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo } from 'react';
import {
  adminOptionsEnum,
  languageOptions,
  languageOptionsEnum,
  userOptionsEnum
} from 'constants/menu';

export function LanguageMenu({
  anchorElLanguage,
  handleCloseLanguageMenu
}: {
  anchorElLanguage: any;
  handleCloseLanguageMenu: () => void;
}): React.ReactElement {
  const { t } = useTranslation();
  const router = useRouter();
  const onAction = useCallback(
    (id: userOptionsEnum | adminOptionsEnum | languageOptionsEnum) => () => {
      switch (id) {
        case languageOptionsEnum.ENGLISH:
          router.push(router.asPath, undefined, { locale: 'en' });
          break;
        case languageOptionsEnum.SPANISH:
          router.push(router.asPath, undefined, { locale: 'es' });
          break;
      }
      handleCloseLanguageMenu();
    },
    [handleCloseLanguageMenu, router]
  );

  const options = useMemo(() => {
    return languageOptions;
  }, []);

  return (
    <Menu
      sx={{ mt: { md: '45px', xs: 'none' } }}
      anchorEl={anchorElLanguage}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={Boolean(anchorElLanguage)}
      onClose={handleCloseLanguageMenu}
    >
      {options.map(({ label, id, subtitle }) => (
        <MenuItem disabled={subtitle} onClick={onAction(id)} key={id}>
          {t(label)}
        </MenuItem>
      ))}
    </Menu>
  );
}
