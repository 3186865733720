export const headerStyles = {
  rightButton: {
    mr: {
      xs: 4,
      md: 8
    },
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '22px',
    letterSpacing: '0.46px',
    textTransform: 'uppercase'
  },
  dropdownButtonContainer: {
    minWidth: '141px',
    px: 2,
    py: {
      xs: 1,
      sm: 2
    },
    mr: {
      xs: 1,
      md: 0
    },
    backgroundColor: '#FFFFFF29',
    borderRadius: 1
  },
  languageDropdownButtonContainer: {
    minWidth: '141px',
    px: 2,
    py: {
      xs: 1,
      sm: 2
    },
    mr: {
      xs: 1,
      md: 0
    },
    display: {
      xs: 'none',
      md: 'flex'
    }
  },
  iconContainer: {
    color: 'white',
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    mr: 2,
    width: '32px',
    height: '32px',
    bgcolor: '#06112e'
  },
  dropdownText: {
    display: {
      xs: 'none',
      md: 'block'
    },
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.15px'
  },
  appbar: {
    backgroundColor: 'text.primary',
    zIndex: 22
  },
  button: {
    my: 2,
    mx: 2,
    color: 'white',
    opacity: 0.7,
    display: 'block',
    '&:hover': {
      opacity: 1
    }
  },
  toolbar: {
    minHeight: {
      xs: '52px',
      sm: '60px'
    }
  },
  wrap: {
    flexGrow: 1,
    display: {
      xs: 'none',
      md: 'flex'
    }
  },
  logo: {
    flexGrow: 1,
    display: {
      xs: 'flex',
      md: 'none'
    }
  },
  menu: {
    display: { xs: 'block', md: 'none' }
  },
  image: (): any => {
    return {
      width: '170px',
      height: '44px',
      imageRendering: '-webkit-optimize-contrast',
      ml: 0,
      mr: 4,
      cursor: 'pointer',
      display: {
        xs: 'none',
        sm: 'flex',
        md: 'flex'
      }
    };
  },
  container: (): any => {
    return {
      flexGrow: 1,
      display: {
        sm: 'flex',
        xs: 'flex',
        md: 'none'
      }
    };
  },
  rightSideHeader: {
    flexGrow: 0,
    display: 'flex',
    width: 'auto',
    justifyContent: 'space-around'
  },
  sideBarContainer: {
    paddingTop: 4,
    paddingLeft: 4,
    paddingRight: 4,
    zIndex: 999,
    width: `100%`,
    height: `100%`,
    background: `#06112E`,
    position: `fixed`,
    top: 0,
    left: 0,
    display: {
      xs: 'flex',
      md: 'none'
    },
    flexDirection: 'column'
  },
  sideBarImage: (showXs: boolean): any => {
    return {
      width: '170px',
      height: '44px',
      imageRendering: '-webkit-optimize-contrast',
      ml: 0,
      mr: 4,
      cursor: 'pointer',
      display: {
        xs: 'flex',
        sm: showXs ? 'flex' : 'flex',
        md: 'flex'
      }
    };
  },
  sideBarHeader: {
    display: 'flex'
  },
  sidebarBody: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 11.96,
    flex: 8
  },
  sideBarMenuItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  sideBarFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1
  },
  sideBarDropdownContainer: {
    minWidth: '141px',
    px: 2,
    py: {
      xs: 1,
      sm: 2
    },
    mr: {
      xs: 1,
      md: 0
    },
    display: {
      xs: 'flex',
      md: 'none'
    }
  },
  sideBarDropdownText: {
    display: {
      xs: 'block',
      md: 'none'
    },
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.15px'
  },
  menuItem: {
    mt: 2.5
  },
  languageTooltip: {
    pl: 4,
    minWidth: 0
  }
} as const;
