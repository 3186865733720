export const helperCardStyles = {
  container: {
    position: 'absolute',
    bottom: 0
  },
  divider: {
    display: {
      xs: 'none',
      sm: 'block'
    },
    width: 238,
    mb: 6
  },
  fab: {
    boxShadow: 'none'
  },
  button: {
    position: {
      xs: 'fixed',
      sm: 'relative'
    },
    bottom: {
      xs: 51,
      sm: 'auto'
    },
    left: {
      xs: 32,
      sm: 'auto'
    },
    zIndex: 20
  },
  dialog: {
    '& .MuiPaper-root': {
      maxWidth: '600px'
    }
  },
  dialogTitle: { py: 6 },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    my: 2
  },
  avatarSize: {
    width: 96,
    height: 96
  }
} as const;
