import {
  MessageOutlined,
  ContentCopy,
  Mail,
  PhoneInTalk,
  Language
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo, useState } from 'react';
import { IRoles } from 'services/models/IRoles';
import { useLoanPending } from 'services/utils';
import { helperCardStyles } from 'styles/helperCardStyles';
import {
  getUserWithRole,
  getFullName,
  formatPhoneNumber,
  formatUriPhoneNumber
} from 'utils/commonUtils';

export function HelpCard() {
  const [open, setOpen] = useState(false);
  const { data: loan } = useLoanPending();
  const { t } = useTranslation();

  const loanOfficer = useMemo(
    () => getUserWithRole(loan, IRoles.OFFICER),
    [loan]
  );

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  if (!loanOfficer) {
    return null;
  }

  const branchNMLS = loanOfficer.user.officerInfo?.branches[0]?.nmls;

  return (
    <Box sx={helperCardStyles.container}>
      <Divider sx={helperCardStyles.divider} />
      <Box sx={helperCardStyles.button}>
        <Fab
          onClick={handleClickOpen}
          color="primary"
          size="small"
          aria-label="message-loan-officer"
          sx={helperCardStyles.fab}
        >
          <MessageOutlined />
        </Fab>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        sx={helperCardStyles.dialog}
      >
        <DialogTitle
          sx={helperCardStyles.dialogTitle}
          variant="h5"
          id="alert-dialog-title"
        >
          {t('help_card.talk_to', { name: getFullName(loanOfficer.user) })}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography variant="h6">
                {t('help_card.your_loan_officer')}
              </Typography>
            </Grid>
            <Grid item>
              <Avatar
                src={
                  loanOfficer.user.profilePicture ??
                  '/images/avatar-default.svg'
                }
                sx={helperCardStyles.avatarSize}
              />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography variant="h6" mt={4}>
                {getFullName(loanOfficer.user)}
              </Typography>
              <Typography variant="body2" color="secondary">
                {t('nmls')} #{loanOfficer.user.officerInfo?.nmls}
              </Typography>
              {branchNMLS && (
                <Typography variant="body2" color="secondary">
                  {t('branch_nmls')} #{branchNMLS}
                </Typography>
              )}
              <Grid container mt={2}>
                <Grid item xs zeroMinWidth>
                  <Box
                    component="a"
                    href={`mailto:${loanOfficer.user.email}`}
                    sx={helperCardStyles.linkContainer}
                  >
                    <Mail color="primary" fontSize="small" />
                    <Typography variant="body2" noWrap>
                      {loanOfficer.user.email}
                    </Typography>
                  </Box>

                  <Box
                    component="a"
                    href={formatUriPhoneNumber(loanOfficer.user.primaryPhone)}
                    sx={helperCardStyles.linkContainer}
                  >
                    <PhoneInTalk color="primary" fontSize="small" />
                    <Typography variant="body2">
                      {formatPhoneNumber(loanOfficer.user.primaryPhone)}
                    </Typography>
                  </Box>
                  {loanOfficer.user.officerInfo?.urlName && (
                    <Box
                      component="a"
                      href={loanOfficer.user.officerInfo.urlName}
                      sx={helperCardStyles.linkContainer}
                      target="_blank"
                    >
                      <Language color="primary" fontSize="small" />
                      <Typography variant="body2" noWrap>
                        {t('visit_site')}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="copy"
                    onClick={() => {
                      navigator.clipboard.writeText(loanOfficer.user.email);
                    }}
                  >
                    <ContentCopy fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {t('close').toUpperCase()}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
