import { Menu, MenuItem } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import { useAppContext } from 'context/uuids';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo } from 'react';
import { baseApi } from 'services/baseApi';
import { useLogoutMutation } from 'services/login';
import { IRoles } from 'services/models/IRoles';
import { useGetProfileQuery } from 'services/profile';
import { setCredentials } from 'services/slice/authSlice';
import { useAppDispatch } from 'services/store';
import {
  adminOptions,
  adminOptionsEnum,
  languageOptionsEnum,
  userOptions,
  userOptionsEnum
} from 'constants/menu';
import { stepRoutes } from 'constants/step-routes';
import { handleUserRoutes } from 'utils/commonUtils';

export function HeaderMenu({
  anchorElUser,
  handleCloseUserMenu
}: {
  anchorElUser: any;
  handleCloseUserMenu: () => void;
}): React.ReactElement {
  const { t } = useTranslation();
  const { data: profile } = useGetProfileQuery();
  const { setLoanUuid } = useAppContext();
  const router = useRouter();
  const [logout] = useLogoutMutation();
  const dispatch = useAppDispatch();

  const onAction = useCallback(
    (id: userOptionsEnum | adminOptionsEnum | languageOptionsEnum) =>
      async () => {
        const route = handleUserRoutes(id);
        if (id === userOptionsEnum.LOG_OUT) {
          await logout().unwrap();
          Sentry.setUser(null);
          if (profile?.role === IRoles.BORROWER) {
            router.push(stepRoutes.LOGIN_BORROWER.route);
          } else {
            router.push(stepRoutes.LOGIN_OFFICER.route);
          }
          await dispatch(
            setCredentials({ accessToken: '', refreshToken: '' })
          ).unwrap();
          dispatch(baseApi.util.resetApiState());
          setLoanUuid('');
        } else {
          router.push(route?.link);
        }

        handleCloseUserMenu();
      },
    [handleCloseUserMenu, logout, profile?.role, dispatch, setLoanUuid, router]
  );
  const options = useMemo(() => {
    if (profile?.role !== IRoles.ADMIN) {
      const newOptions = [...userOptions];
      if (profile && profile.settings && profile.settings.isRateQuoteEnabled) {
        newOptions.splice(1, 0, {
          label: 'rate-quote_table.rate-quote',
          id: adminOptionsEnum.RATE_QUOTE,
          link: '/admin/rate-quote'
        });
      }
      return newOptions;
    } else {
      return adminOptions;
    }
  }, [profile]);

  return (
    <>
      <Menu
        sx={{ mt: '45px' }}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {options.map(({ label, id, subtitle }) => (
          <MenuItem disabled={subtitle} onClick={onAction(id)} key={id}>
            {t(label)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
